.profile-header{
  position: relative;
  height: 150px;
  background-color: #143A61;
}

.border-container{
  width: 150px;
  height: 150px;
  position: absolute;
  bottom: -30%;
  left: calc(50% - 75px);
  border-radius: 50%;
  overflow: hidden;
  padding: 0px;
}

.border-animator{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 5px solid #ffd700;
  border-color: rgb(43, 211, 237);
  border-right-color: transparent;
  border-top-color: transparent;
  border-radius: 50%;          
  box-sizing: border-box;       
  animation: rotate 1s ease-in-out infinite;
  z-index: 1;

}
.border-container img{
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.add-photo-btn, .full-photo-btn{
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  border-radius: 50%;
  background-color: rgba(54, 50, 50, 0.5);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 12px;
  font-weight: light;
  line-height: 25px;
}

.full-photo-btn{
  background-color: transparent;
}


/* Profile Photo Upload/Delete Animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);     /* Start rotation at 0 degrees */
  }
  100% {
    transform: rotate(360deg);   /* End rotation at 360 degrees */
  }
}

.image-source-picker{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 220px !important;
  z-index: 1000000;
}

.source-picker-buttons button{
  border: none;
  background: none;;
}
.source-picker-buttons button:active:not(:disabled), .source-picker-buttons label:active{
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}


.image-resize-container{
  max-width: 600px;
  height: 30vh;
  margin: auto auto;
  margin-top: 100px;
  position: relative;
  transform: rotateZ(0deg);
}

.resize-box {
  top: 0;
  left: 0;
  min-width: 192px;
  min-height: 192px;
  position: absolute;
  border: 2px solid rgba(255, 255, 255, 0.9);
  z-index: 1;
  cursor: move;
}

.photo-feeback-toast{
  width: 220px;
}

.account-form-buttons button{
  width: 80px;
  height: 30px;
}

.account-form input{
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.account-form input:focus{
  box-shadow: none;
  color: rgb(20, 127, 214);
}

.edit-photo-btn{
  border: none;
}
.edit-photo-btn:active{
  background-color: grey !important;
}
































/* 
.cameraContainer{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: rgb(0, 0, 0);
}

.cameraContainer video{
  object-fit: cover;
  width: 100vw; 
  height: calc(100vh); 
}

.cameraContainer canvas {
  object-fit: cover;
  width: 100vw;
  height: calc(100vh);
}
.camera-control{
  display: block;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  background-color: rgb(0, 0, 0);
}


.btn-capture{
  padding: 35px;
  border: none;
}

.btn-repeat:active{
  color: grey;
}
.btn-capture:active{
  background-color: grey;
}

.btn-repeat{
  color: white;
  background-color: black;
  border: none;
  padding: 10px;
  position: absolute;
  right: 30px;
}

.camera-preview-btn{
  background-color: black;
  color: white;
  border: none;
} */